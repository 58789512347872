var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { attrs: { id: "change-email" } },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  attrs: {
                    titleTranslationKey: "components.profile.ChangeEmail.title",
                    ready: true,
                    model: _vm.$v,
                    onSave: _vm.onSave,
                    showDiscardButton: true
                  },
                  on: { discardChanges: _vm.resetForm }
                },
                [
                  _c("ValidatedInput", {
                    attrs: {
                      label: "Aktuelles Passwort",
                      translationKey: "changeEmail.password",
                      field: _vm.$v.user.password,
                      value: _vm.$v.user.password.$model,
                      autocomplete: "password",
                      type: "password",
                      "data-cy": "password"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.user.password, "$model", $event)
                      },
                      focusChange: _vm.onFocusChange
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      label: "Neue E-Mail",
                      translationKey: "changeEmail.newEmail",
                      field: _vm.$v.user.newEmail,
                      autocomplete: "email",
                      "data-cy": "new-email",
                      value: _vm.$v.user.newEmail.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.user.newEmail, "$model", $event)
                      },
                      focusChange: _vm.onFocusChange
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("HelpCard", {
                attrs: {
                  focusFor: _vm.focusFor,
                  name: "components.profile.ChangeEmail.info.box1"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Notification", {
        attrs: {
          icon: "cilCheck",
          text: _vm.$t(
            "components.profile.ChangeEmail.notificationMsg.success"
          ),
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function($event) {
            _vm.showSuccessNotification = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }