var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { attrs: { id: "change-email-settings" } },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  attrs: {
                    titleTranslationKey:
                      "components.profile.ChangeEmailSettings.title",
                    ready: _vm.ready,
                    model: _vm.$v,
                    onSave: _vm.save,
                    showDiscardButton: true
                  },
                  on: { discardChanges: _vm.resetForm }
                },
                [
                  _c("ValidatedCheckbox", {
                    attrs: {
                      field: _vm.$v.emailSettings.allowEmailForBookings,
                      checked:
                        _vm.$v.emailSettings.allowEmailForBookings.$model,
                      translationKey: "emailSettings.allowEmailForBookings",
                      disabled: "disabled"
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.emailSettings.allowEmailForBookings,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedCheckbox", {
                    attrs: {
                      field: _vm.$v.emailSettings.allowEmailForPersonalOffers,
                      checked:
                        _vm.$v.emailSettings.allowEmailForPersonalOffers.$model,
                      translationKey:
                        "emailSettings.allowEmailForPersonalOffers"
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.emailSettings.allowEmailForPersonalOffers,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedCheckbox", {
                    attrs: {
                      field: _vm.$v.emailSettings.allowEmailForFeedback,
                      checked:
                        _vm.$v.emailSettings.allowEmailForFeedback.$model,
                      translationKey: "emailSettings.allowEmailForFeedback"
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.emailSettings.allowEmailForFeedback,
                          "$model",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Notification", {
        attrs: {
          icon: "cilCheck",
          text: _vm.$t(
            "components.profile.ChangeEmailSettings.notificationMsg.success"
          ),
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function($event) {
            _vm.showSuccessNotification = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }