var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-testid": "change-password-view" } },
    [
      _c(
        "CRow",
        { attrs: { id: "change-password" } },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  attrs: {
                    titleTranslationKey:
                      "components.profile.ChangePassword.title",
                    testid: "change-password",
                    ready: true,
                    model: _vm.$v,
                    onSave: _vm.onSave,
                    showDiscardButton: true
                  },
                  on: { discardChanges: _vm.resetForm }
                },
                [
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "changePassword.currentPassword",
                      field: _vm.$v.user.password,
                      value: _vm.$v.user.password.$model,
                      autocomplete: "password",
                      type: "password"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.user.password, "$model", $event)
                      },
                      focusChange: _vm.onFocusChange
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "changePassword.newPassword",
                      field: _vm.$v.user.newPassword,
                      value: _vm.$v.user.newPassword.$model,
                      autocomplete: "new-password",
                      type: "password"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.user.newPassword,
                          "$model",
                          $event
                        )
                      },
                      focusChange: _vm.onFocusChange
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "changePassword.newPasswordConfirmation",
                      field: _vm.$v.user.newPasswordConfirmation,
                      value: _vm.$v.user.newPasswordConfirmation.$model,
                      autocomplete: "new-password",
                      type: "password"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.user.newPasswordConfirmation,
                          "$model",
                          $event
                        )
                      },
                      focusChange: _vm.onFocusChange
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("HelpCard", {
                attrs: {
                  focusFor: _vm.focusFor,
                  name: "components.profile.ChangePassword.info.box1"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.showSuccessNotification
        ? _c("Notification", {
            attrs: {
              icon: "cilCheck",
              text: _vm.$t(
                "components.profile.ChangePassword.notificationMsg.success"
              )
            },
            on: {
              onClose: function($event) {
                _vm.showSuccessNotification = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }